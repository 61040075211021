import { Brand } from "@/api/generated/BMWInterfaces"
import LinkWithParams from "@/lib/common/LinkWithParams"
import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import { lowerFindCarClick, sendHomepageFilter } from "@/lib/gtm"
import colors from "@/lib/theme/colors"
import styled from "@emotion/styled"
import { Button, Typography } from "@mui/material"
import Image from "next/image"
import { useState } from "react"
import SliderFilter from "./Slider"

const OuterLayout = styled.div`
  padding: 56px 16px;
  width: 100%;
  background-color: ${colors.directDisable};
  display: flex;

  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: center;
    padding: 64px 40px;
  }
`

const Layout = styled.div`
  max-width: 1200px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
  }
`

const InnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 64px;
  }
`

const LogosFlex = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;

  ${(props) => props.theme.breakpoints.up("md")} {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`

const StyledLogo = styled.button<{ selected: boolean }>`
  display: flex;
  /* width: 76px; */
  width: 100%;
  height: 72px;
  padding: 8px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  border-radius: 8px;
  border: 1.5px solid
    ${(props) => (props.selected ? colors.directBlack : colors.directVeryLightGray)};
  background: white;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
    width: 150px;
    /* width:100%; */
    height: 143px;
  }
`

const SliderContainer = styled.div`
  max-width: 816px;
  width: 100%;
  display: flex;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-left: 0;
    padding-right: 0;
    align-self: center;
  }
`

const ButtonContainer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    max-width: 328px;
    width: 100%;
    align-self: center;
  }
`

function generateSearchUrl({
  minMaxPrice,
  selectedBrands,
}: {
  minMaxPrice: number[]
  selectedBrands: number[]
}): string {
  const params = new URLSearchParams()
  params.append("minPrice", minMaxPrice[0].toString())
  params.append("maxPrice", minMaxPrice[1].toString())

  if (selectedBrands.length > 0) {
    selectedBrands.forEach((brandId) => {
      params.append("brandId", brandId.toString())
    })
  }
  return `/search?${params.toString()}`
}

export default function CarInterest({
  data,
  minPrice,
  maxPrice,
}: {
  data: Brand[]
  minPrice: number
  maxPrice: number
}): React.ReactElement {
  const [selectedBrands, setSelectedBrands] = useState<number[]>([])
  const [minMaxPrice, setMinMaxPrice] = useState<number[]>([minPrice, maxPrice])
  const selectedAllBrands = selectedBrands.length === 0
  const handleBrandChange = ({ allButton, id }: { allButton?: boolean; id?: number }): void => {
    if (allButton) {
      setSelectedBrands([])
    }
    if (id) {
      setSelectedBrands((prev) => {
        if (prev.includes(id)) {
          return prev.filter((brandId) => brandId !== id)
        } else {
          return [...prev, id]
        }
      })
    }
  }
  const handlePriceChange = (event: Event, newValue: number | number[]): void => {
    setMinMaxPrice(newValue as number[])
  }
  const href = generateSearchUrl({ minMaxPrice, selectedBrands })
  const onClickHandler = (): void => {
    sendHomepageFilter(selectedBrands.join(", "), `${minMaxPrice[0]}-${minMaxPrice[1]}`)
    lowerFindCarClick(selectedBrands.join(", "), `${minMaxPrice[0]}-${minMaxPrice[1]}`)
  }
  return (
    <OuterLayout>
      <Layout>
        <Mobile>
          <Typography variant="h3">מה יהיה הרכב הבא שלך?</Typography>
        </Mobile>
        <Desktop centerHorizontally>
          <Typography variant="h2">מה יהיה הרכב הבא שלך?</Typography>
        </Desktop>
        <InnerLayout>
          <LogosFlex>
            <StyledLogo
              selected={selectedAllBrands}
              onClick={() => handleBrandChange({ allButton: true })}
            >
              <>
                <Mobile>
                  <Image
                    src={"/images/directLogoSearch.png"}
                    alt={"all brands"}
                    width={68}
                    height={40}
                  />
                </Mobile>
                <Desktop>
                  <Image
                    src={"/images/directLogoSearch.png"}
                    alt={"all brands"}
                    width={130}
                    height={76}
                  />
                </Desktop>
              </>
              <Mobile>
                <Typography variant="body3">הכל</Typography>
              </Mobile>
              <Desktop>
                <Typography variant="h3">הכל</Typography>
              </Desktop>
            </StyledLogo>
            {data.map((el, i) => (
              <StyledLogo
                key={i}
                selected={!!selectedBrands.find((x) => x === el.id)}
                onClick={() => handleBrandChange({ id: el.id })}
              >
                {el.logo && (
                  <>
                    <Mobile>
                      <Image
                        src={el.logo.imageUrl}
                        alt={el.logo.alt || el.name}
                        width={68}
                        height={40}
                      />
                    </Mobile>
                    <Desktop>
                      <Image
                        src={el.logo.imageUrl}
                        alt={el.logo.alt || ""}
                        width={130}
                        height={76}
                      />
                    </Desktop>
                  </>
                )}
              </StyledLogo>
            ))}
          </LogosFlex>
          <SliderContainer>
            <SliderFilter
              brandMax={maxPrice}
              brandMin={minPrice}
              minMaxPrice={minMaxPrice}
              handleChange={handlePriceChange}
            />
          </SliderContainer>
          <ButtonContainer>
            <Button
              onClick={onClickHandler}
              variant="contained"
              LinkComponent={LinkWithParams}
              href={href}
              fullWidth
            >
              חפשו לי רכב
            </Button>
          </ButtonContainer>
        </InnerLayout>
      </Layout>
    </OuterLayout>
  )
}
